import {findIn, removeClass, addClass} from "@elements/dom-utils";
import {onFind} from "./elements-packages/init-modules-in-scope";


export function init () {
    onFind('.js-countdown', function (element) {
        console.log('elements');
        let endDate = element.getAttribute('data-countdown-enddate')
        let deadline = new Date(Date.parse(new Date(endDate)) );

        console.log('endDate',endDate);
        initializeClock(element, deadline);
    });
}

function initializeClock(element, endtime) {
    let daysSpan = findIn('.js-countdown__days', element),
        hoursSpan = findIn('.js-countdown__hours', element),
        minutesSpan = findIn('.js-countdown__minutes', element),
        secondsSpan = findIn('.js-countdown__seconds', element);
        secondsSpan = findIn('.js-countdown__seconds', element);

    function updateClock() {
        let t = getTimeRemaining(endtime);

        daysSpan.innerHTML = t.days;
        hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
        minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
        secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

        if (t.total <= 0) {
            addClass('hide', findIn('.countdown-wrapper', element));
            removeClass('hide', findIn('.js-countdown__finished', element));
            clearInterval(timeinterval);
        }
    }

    updateClock(); // run function once at first to avoid delay
    let timeinterval = setInterval(updateClock,1000);

    element.removeAttribute("hidden");
}

function getTimeRemaining(endtime) {
    let t = Date.parse(endtime) - Date.parse(new Date());

    /***** CONVERT THE TIME TO A USEABLE FORMAT *****/
    let seconds = Math.floor( (t / 1000) % 60 );
    let minutes = Math.floor( (t / 1000 / 60) % 60 );
    let hours = Math.floor( (t / (1000 * 60 * 60)) %  24);
    let days = Math.floor( t / (1000 * 60 * 60 * 24) );

    /***** OUTPUT THE CLOCK DATA AS A REUSABLE OBJECT *****/
    return {
        'total': t,
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': seconds
    };
}