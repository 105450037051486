"use strict";

import {findIn} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import Swiper from 'swiper';
import {onEnterViewPort} from "@elements/viewport-utils";
import {on, findAllIn, find, addClass, removeClass, setText, getAttribute} from '@elements/dom-utils';

export function init () {

    onFind('.js-region-slider', function (sliderElement) {
        let slidesPerViewDesktop = '8',
            slidesPerViewMobile = 'auto';

        let slider = findIn('.js-region-slider__slider', sliderElement);
        let hotspotsWrapper = find('.js-region-hotspots');
        let hotspots = findAllIn('.js-region-hotspots__hotspot', hotspotsWrapper);
        let regionLink = find('.js-region-slider__link');
        let initialSlide = +sliderElement.getAttribute('data-region-slider-initial-slide') || 0;

        let sliderOptions = {
            keyboard: {
                enabled: true,
            },
                slideToClickedSlide: true,
                initialSlide: initialSlide,
                loop: true,
                lazyLoading: false,
                slidesPerView: slidesPerViewMobile,
                centeredSlides: true,
                updateOnWindowResize: false, /* needed for ios bug */
                spaceBetween: 18,
                on: {
                    slideChangeTransitionStart: function() {
                        if(slider && findIn('.swiper-slide-active', slider)) {
                            let hotspotName = findIn('.swiper-slide-active', slider).getAttribute('data-region-id');
                            let hotspotText = findIn('.swiper-slide-active .region-slider__title', slider).textContent.trim();
                            let linkHref = findIn('.swiper-slide-active', slider).getAttribute('data-region-link');
                            let linkText = findIn('.swiper-slide-active', slider).getAttribute('data-region-link-text');

                            let hotspot = findIn(`[data-hotspot-id=${hotspotName}]`, hotspotsWrapper);

                            hotspots.map(function(hotspot) {
                                removeClass('active', hotspot);
                            });

                            addClass('active', hotspot);
                            regionLink.setAttribute('href', linkHref);
                            regionLink.setAttribute('data-event-tracking-label', hotspotText);
                            if(linkText) {
                                setText(linkText, regionLink);
                            }
                            addClass('btn-animation', regionLink);
                            on('transitionend', function() {
                                removeClass('btn-animation', regionLink);
                            }, regionLink)
                        }
                    }
                },
                breakpoints: {
                    // when window width is >= 1200px
                    1200: {
                        slideToClickedSlide: true,
                        slidesPerView: slidesPerViewDesktop,
                        spaceBetween: 28,
                        allowTouchMove: true,
                        updateOnWindowResize: true,
                        initialSlide: initialSlide
                    }
                }
        };

        initSlider(slider, sliderOptions);


    });

}

function initSlider($slider, sliderOptions) {
    onEnterViewPort($slider, function ($slider) {
        return new Swiper($slider, sliderOptions);
        // loadImg($imgToPreload);
    });
}
