"use strict";

import Swiper from 'swiper';
import {loadImg} from "@elements/lazy-img";
import {onEnterViewPort} from "@elements/viewport-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {findIn} from "@elements/dom-utils";

const defaultSelectors = {
    sliderContainer: '.js-swiper',
    slider: '.js-swiper__slider',
    sliderScrollbar: '.js-swiper__scrollbar',
    sliderPrev: '.js-swiper__prev',
    sliderNex: '.js-swiper__next'
};

const defaultOptions = {
    mediaQuery : null
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.sliderContainer, function (baseElement) {
        createSwiper(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createSwiper(baseElement, options = defaultOptions, selectors = defaultSelectors) {

    let slider = findIn(selectors.slider, baseElement);


    let sliderOptions = {
        lazy: false,
        keyboard: {
            enabled: true,
        },
        ...(transformOptions(options,baseElement))
    };

    // const $tabPaneParent = closest('.tab-pane:not(.active)',slider);
    // const $collapseParent = closest('.collapse',slider);
    // const $modalParent = closest('.modal',slider);
    //
    // if ($tabPaneParent && $tabPaneParent.length) {
    //     /*wait for the initializiation of the slider if it is hidden in a tab */
    //     const $tabTrigger = $('[href="#' + $tabPaneParent.attr('id') + '"],[data-toggle=tab][data-target="#' + $tabPaneParent.attr('id') + '"]');
    //
    //     $tabTrigger.one('shown.bs.tab', function () {
    //         initSlider(slider, sliderOptions);
    //     });
    //
    // } else if ($collapseParent && $collapseParent.length) {
    //     /* wait until slide is shown if it is initially hidden in a collapse/accordion */
    //     $collapseParent.one('shown.bs.collapse', function () {
    //         initSlider(slider, sliderOptions);
    //     });
    //
    // } else if ($modalParent && $modalParent.length) {
    //     /* wait until slide is shown if it is initially  hidden in a modal */
    //     $modalParent.one('shown.bs.modal', function () {
    //         initSlider(slider, sliderOptions);
    //     });
    //
    // } else {
    //     // no tab, accordion, modal -> init slider as usual
    //     initSlider(slider, sliderOptions);
    // }

    if(!options.mediaQuery || matchMedia(options.mediaQuery).matches){
        slider =  initSlider(slider, sliderOptions);
    }

    return slider;

}

const transformOptions = (options, container) => {
    options = ({
        ...options,
        scrollbar: {...options.scrollbar},
        navigation: {...options.navigation},
    });
    

    if (options.scrollbar && options.scrollbar.findElement) {
        options.scrollbar.el = options.scrollbar.findElement(container);
    }

    if (options.navigation && options.navigation.findNextElement) {
        options.navigation.nextEl = options.navigation.findNextElement(container);
    }

    if (options.navigation && options.navigation.findPrevElement) {
        options.navigation.prevEl = options.navigation.findPrevElement(container);
    }

    if (options.pagination && options.pagination.el) {
        options.pagination.el = options.pagination.el(container);
    }

    return options
};


function initSlider($slider, sliderOptions) {
    return new Swiper($slider, sliderOptions);
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + n);
    }
}


// export const initInScope = createInitInScope();
