"use strict";

import {onFind} from "./elements-packages/init-modules-in-scope";
import {findIn,removeClass, on, addClass} from '@elements/dom-utils';

const selectors = {
    base: '.js-scrolling-row',
    row: '.js-scrolling-row__row',
    prev: '.js-scrolling-row__prev',
    next: '.js-scrolling-row__next',
    item: '.js-scrolling-row__item'
};

export function init () {
    onFind(selectors.base, function (baseElement) {
        let row = findIn(selectors.row, baseElement);
        let prev = findIn(selectors.prev, baseElement);
        let next = findIn(selectors.next, baseElement);
        let item = findIn(selectors.item, baseElement);
        let itemSize = item ? item.clientWidth : 0;

        updateButtons();

        on('scroll', function() {
            updateButtons();
        }, row);

        if(prev) {
            on('click', function() {
                row.scrollBy({
                    top: 0,
                    left: -itemSize,
                    behavior: 'smooth'
                });
            }, prev);
        }

        if(next) {
            on('click', function() {
                row.scrollBy({
                    top: 0,
                    left: itemSize,
                    behavior: 'smooth'
                });
            }, next);
        }

        function updateButtons() {
            if(prev) {
                if(row.scrollLeft === 0) {
                    addClass('scrolling-row__button--disabled', prev);
                } else {
                    removeClass('scrolling-row__button--disabled', prev);
                }
            }

            if(next) {
                if(row.scrollWidth - row.scrollLeft > row.clientWidth) {
                    removeClass('scrolling-row__button--disabled', next);
                } else {
                    addClass('scrolling-row__button--disabled', next);
                }
            }

        }
    });
}


