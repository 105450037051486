"use strict";

import {findIn} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {createSwiper} from "./swiper";

export function init () {
    onFind('.js-experience-slider', function (sliderElement) {
        let slidesPerViewDesktop = 3,
            slidesPerViewMobile = 1.3;

        createSwiper(sliderElement,{
                // mediaQuery: '(max-width: 767px)',
                loop: false,
                slidesPerView: slidesPerViewMobile,
                spaceBetween: 28,
                centeredSlides: true,
                breakpoints: {
                    // when window width is >= 768px
                    768: {
                        slidesPerView: slidesPerViewDesktop,
                        spaceBetween: 28,
                        allowTouchMove: true,
                        centeredSlides: true
                    }
                },
                navigation: {
                    findNextElement: swiperContainer => findIn('.js-experience-slider__next', swiperContainer),
                    findPrevElement: swiperContainer => findIn('.js-experience-slider__prev', swiperContainer),
                },
                scrollbar: {
                    el: '.js-experience-slider__scrollbar',
                    hide: false,
                },
            },
            {
                sliderContainer: '.js-experience-slider',
                slider: '.js-experience-slider__slider',
                sliderPrev: '.js-experience-slider__prev',
                sliderNex: '.js-experience-slider__next',
                sliderScrollbar: '.js-experience-slider__scrollbar',
            })
    });
}