"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import {initInScope,onFind} from "./elements-packages/init-modules-in-scope";
import {findIn, find, hasClass,findAllIn,removeClass, on} from '@elements/dom-utils';

export function init () {
    onFind('.js-render-template', function (renderTemplates) {
        onEnterViewPort(renderTemplates, function (renderItem) {
            renderTemplate(renderItem)
        }, { offset: 300 });

        if (location.hash) {
            renderAllTemplates();

            let target = find(location.hash);
            if (target && target.length) {
                window.scrollTo(0, target.offset().top - 50);
            }
        }
    });

    onFind('.js-render-template--on-load', function (renderTemplates) {
        window.addEventListener('load', function () {
            renderTemplate(renderTemplates)
        });
    });
}

export function renderTemplate(renderItem) {
    let template = findIn('.js-render-template__template',renderItem);
    let parent = renderItem.parentNode;

    if(template) {
        renderItem.innerHTML = template.innerHTML;
        removeClass('js-render-template',renderItem);

        template.remove();

        initInScope(renderItem);
    }
}

export function renderAllTemplates() {
    let templates = findAllIn('.js-render-template', document.body);
    templates.map(template => {
        if(!hasClass('js-render-template--not-initially', template)) {
            renderTemplate(template);
        }
    });
}