"use strict";

import {findIn, on, hasClass, addClass, removeClass} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
   onFind('.js-info-overlay', overlay => {
       let toggle = findIn('.js-info-overlay__toggle', overlay);
       let content = findIn('.js-info-overlay__content', overlay);

       on('click', evt => {
           if(!hasClass('is-open', toggle)){
               addClass('is-open', toggle);
               addClass('is-open', content);
           }else{
               removeClass('is-open', toggle);
               removeClass('is-open', content);
           }
       }, toggle);
   });
}