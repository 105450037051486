import * as quickfinder from './elements-packages/quickfinder';
import {onFind} from "./elements-packages/init-modules-in-scope";
import {closest, findIn, on, is, insertAdjacentHTML} from "@elements/dom-utils";
import {translate} from '@elements/translations';

export function init(renderChildAgeInput = defaultRenderChildAgeInput) {
    quickfinder.init(undefined,
        renderChildAgeInput
    );

    onFind('.js-quickfinder', function (form) {
        let isLocationChecked = false;
        let searchNearbyCheckbox = findIn('.js-quickfinder__search-nearby', form);

        if (!is('form', form)) {
            form = closest('form', form);
        }

        if (searchNearbyCheckbox) {
            on('submit', function (evt) {
                if (isLocationChecked) {
                    return;
                }

                if (!'geolocation' in navigator) {
                    hide();
                    return;
                }

                if (searchNearbyCheckbox.checked
                    && 'geolocation' in navigator) {
                    evt.preventDefault();
                    navigator.geolocation.getCurrentPosition((position) => {
                        insertAdjacentHTML('beforeend', `
                            <input type="hidden" name="sorting" value="nearbySearchAsc" />
                            <input type="hidden" name="nearbySearchLat" value="${position.coords.latitude}" />
                            <input type="hidden" name="nearbySearchLong" value="${position.coords.longitude}" />
                            <input type="hidden" name="nearbySearchId" value="location" />
                        `, form);

                        form.submit();
                    }, function () {
                        form.submit();
                    });
                }
            }, form)
        } else {
            hide();
        }

        function hide() {
            let searchNearby = findIn('.js-quickfinder__search-nearby-container', form);

            if (searchNearby) {
                searchNearby.setAttribute('hidden', 'hidden');
            }
        }
    })
}

function defaultRenderChildAgeInput({index, value, onChange}) {
    let markupElement = document.createElement('div');

    let markup = `<div class="col-6" id="quickfinder-child-age-${index}">
    <div class="select form-group form-group--sm has-value">
        <div class="select__input-wrapper position-relative">
            <label class="form-control-label floating-label" for="childAge${index}"> 
                ${translate('qf.childAge')} ${index}
            </label>
            <select name="ca0[]" id="childAge${index}" name="childAge${index}" class="form-control js-floating-label has-value js-quickfinder__child-age-input" required>
                <option class="d-none" value="" ${value ? '' : 'selected'} ></option>`;

    for (let i = 0; i < 18; i++) {
        markup += `<option value="${i}" ${i == value ? 'selected': ''}>${i} ${translate('qf.Jahre')}</option>`;
    }

    markup += `</select><div class="form-errors invalid-feedback"></div></div></div></div>`;

    markupElement.innerHTML = markup;
    let quickfinderItem = markupElement.childNodes[0];    //remove wrapper-div

    quickfinderItem.addEventListener('change', function(e) {
        onChange(e.target.value);
    });

    return quickfinderItem;
}