"use strict";

import {getPrefixedDataSet} from '@elements/data-set-utils';
import {onFind} from "./init-modules-in-scope";
import {findIn, findAll, on} from "@elements/dom-utils";

const defaultOptions = {
    video: false,
    animateThumb: false,
    download: false,
    counter: false,
    closeOnBgTap: true
};

const defaultSelectors = {
    base: '.js-lightbox',
    item: '.js-lightbox__item'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {

    onFind(selectors.base, function (baseElement) {
        createLightbox(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createLightbox(lightbox, options = defaultOptions, selectors = defaultSelectors) {

    let lightboxItems = findIn(selectors.item, lightbox);

    let elementOptions = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('lightbox', lightbox)
    };

    return import('lightgallery.js').then(function () {

        if (elementOptions.video) {
            /* the following imports are only needed if there are videos in your lightGallery*/
            /* IMPORTANT: Do not forget to add 'lg-video-object lg-html5' to video-elements*/
            /* IMPORTANT: The controls attribute must be set on the <video>*/

            import('vimeo-froogaloop2');
            import('lg-video.js');
        }

        lightGallery(lightbox, {
            ...elementOptions,
            selector: selectors.item,
        });

        //prevent isClickable
        const lightboxClickHandler = evt => {
            evt.stopImmediatePropagation();
        };

        if (lightboxItems){
            on('click', lightboxClickHandler, lightboxItems);
        }

        if (options.closeOnBgTap){
            lightbox.addEventListener('onAfterOpen', function(event){
                closeGalleryOnBgTap(event);
            }, false);

            lightbox.addEventListener('onAfterSlide', function(event){
                closeGalleryOnBgTap(event);
            }, false);
        }

        return window.lgData[lightbox.getAttribute('lg-uid')];
    });

}

function closeGalleryOnBgTap(event){
    let imgWrapItems = findAll('.lg-img-wrap');

    imgWrapItems.forEach((item) => {
        on('touchstart', (evt) => {
            if(!hasClass('lg-image',evt.target)){
                window.lgData[event.target.getAttribute('lg-uid')].destroy(false);
            }
        }, item);
    });
}