import {on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {createAjaxForm} from "@elements/ajax-form";

export function init() {
    onFind('.js-ajax-form', function (baseElement) {
        let api = createAjaxForm(
            baseElement
        );

        on('reset.ajax-form', (evt) => {
            api.submit();
        }, baseElement);
    });
}
