'use strict'

import loadScript from "@elements/load-script";
import {hasClass, findIn, setAttribute, removeAttribute, on, trigger} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {isValid} from "./formValidation";

//recaptcha v3

const API_KEY = _config.recaptchaPublicKey;

export function init() {
    onFind('.js-recaptcha', function (baseElement) {
        loadRecaptchaAPI().then(function () {
            let isValidated = false;

            on('submit', function (evt) {
                let hasFormValidation = hasClass('js-form-validation', baseElement) || false;

                if ((hasFormValidation && isValid(baseElement)) || !hasFormValidation) {
                    let recaptchaResponse = findIn('.js-recaptcha__response', baseElement);
                    let recaptchaAction = findIn('.js-recaptcha__action', baseElement);

                    if (window['grecaptcha'] && !isValidated) {
                        evt.preventDefault();

                        grecaptcha.ready(function () {
                            grecaptcha.execute(API_KEY, {action: recaptchaAction.value}).then(function (token) {
                                recaptchaResponse.value = token;
                                isValidated = true;
                                baseElement.submit();
                            });
                        });
                    }

                }
            }, baseElement);
        });
    });
}

export function loadRecaptchaAPI() {
    return loadScript(`https://www.google.com/recaptcha/api.js?render=${API_KEY}`);
}
