"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn, findAllIn, findAll, addClass, removeClass} from "@elements/dom-utils";

export function init() {
    window.addEventListener('CookiebotOnAccept', function (e) {
        findAll('.js-consent-overlay').forEach(item => {
            let consentType = item.getAttribute('data-consent-type');
            let overlay = findIn('.js-consent-overlay__overlay',item),
                iframe = findAllIn('.js-consent-overlay__iframe',item)
            if (consentType === 'marketing' && Cookiebot.consent.marketing){
                if(iframe){
                    iframe.forEach(function(iframeItem) {
                        let iframeSrc = iframeItem.getAttribute('data-iframe-src');

                        if(iframeSrc){
                            iframeItem.setAttribute('src',iframeSrc);
                        }
                        setTimeout(function(){
                            removeClass('show',overlay);
                        }, 800);
                    });
                }else{
                    removeClass('show',overlay);
                }

            }
        });

    }, false);


    onFind('.js-consent-overlay', function (baseElement) {
        let consentType = baseElement.getAttribute('data-consent-type');
        let overlay = findIn('.js-consent-overlay__overlay',baseElement),
            btn =  findIn('.js-consent-overlay__btn',baseElement),
            iframe = findAllIn('.js-consent-overlay__iframe',baseElement),
            loading = findIn('.js-consent-overlay__loading',baseElement);

        if (consentType === 'marketing'){
            if (Cookiebot.consent.marketing) {
                if(iframe.length > 0){
                    iframe.forEach(function(iframeItem) {
                        let iframeSrc = iframeItem.getAttribute('data-iframe-src');

                        if(iframeSrc){
                            iframeItem.setAttribute('src',iframeSrc);
                        }
                        setTimeout(function(){
                            removeClass('show',overlay);
                        }, 800);
                    });
                }else{
                    removeClass('show',overlay);
                }
            }else{
                addClass('show',overlay)
            }
        }

        on('click', (evt) => {
            let dataSrc;

            if(iframe.length > 0){
                iframe.forEach(function(iframeItem) {
                    let iframeSrc = iframeItem.getAttribute('data-iframe-src');
                    dataSrc = iframeItem.getAttribute('data-src');

                    if(iframeSrc){
                        iframeItem.setAttribute('src',iframeSrc);
                    }

                    if (consentType === 'marketing'){
                        Cookiebot.submitCustomConsent(null, null, true);

                        setTimeout(function(){
                            if (dataSrc){
                                loading.removeAttribute('hidden');
                                location.reload();
                            }else{
                                removeClass('show',overlay);
                            }
                        }, 800);
                    }
                });

            }else{
                if (consentType === 'marketing'){
                    Cookiebot.submitCustomConsent(null, null, true);
                    removeClass('show',overlay);
                }
            }

        },btn);

    });

}
