"use strict";

import {onFind} from "./elements-packages/init-modules-in-scope";
import {findIn,closest,addClass,hasClass} from '@elements/dom-utils';
let setLinkActiveState = false;


export function init () {
    onFind('.js-nav-active-state', function (baseElement) {

        let url = window.location.pathname;
        let link = findIn('a.js-nav-active-state__link[href="'+url+'"]', baseElement);
        if(link){
            checkLinks(link);
        }

        //for object detail page remove last url part an try again
        if(!setLinkActiveState ){
            url = url.substring(0, url.lastIndexOf('/'));
            link = findIn('a.js-nav-active-state__link[href="'+url+'"]', baseElement);
            if(link){
                checkLinks(link);
            }
        }
    });
}

function checkLinks(link){
    let collapse = closest('.collapse', link);
    if(collapse){
        addClass('show', collapse);
    }

    let level = closest('.js-nav__list-item.has-children.mega-nav__item--level-0', link);
    if(level){
        addClass('is-open', level);
        setLinkActiveState = true;
    }

    if(hasClass('mega-nav__item-content--level-0', link)){
        addClass('is-open', closest('.js-nav__list-item', link));
        setLinkActiveState = true;
    }
}