"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import Pristine from "../libs/pristine/pristine.js";
import {onEnterViewPort} from "@elements/viewport-utils";
import {matches, trigger} from "@elements/dom-utils";

const defaultOptions = {
    classTo: 'form-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'form-errors invalid-feedback'
};

const defaultSelectors = {
    base: '.js-form-validation'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createFormValidation(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createFormValidation(form, options, selectors) {
    Pristine.setLocale('en');
    onEnterViewPort(form, function () {
        // Pristine.addValidator("max-files", function (value, param1) {
        //     console.log("The value is ", value);
        //     console.log("Param1 is ", param1);
        //     return parseInt(param1) <= value
        // }, "The value (${0}) must be lower than ${1}", 1, false);

        const pristine = new Pristine(form, options);

        form.addEventListener('submit', function (e) {
            if (!pristine.validate()) {
                e.preventDefault();
            }else{
                trigger('form-validation.submit', form);
            }
        });

        form.pristine = pristine;
        return form.pristine;
    });
}

export function isValid(form) {
    if (matches('form', form)) {
        const pristine = new Pristine(form, defaultOptions);
        return pristine.validate();
    } else {
        return 'Using pristine without form';
    }
}

export function isValidInput(form, inputs) {
    if(matches('form', form)){
        const pristine = new Pristine(form, defaultOptions);
        return pristine.validate(inputs, true);
    }else{
        return 'Using pristine without form';
    }
}

export function isValidFileUpload(form, fileList) {
    if(matches('form', form)){
        Pristine.addValidator("max-files", function (value, param1) {
            return parseInt(param1) >= fileList.length;
        }, "The value (${0}) must be lower than ${1}", 1, true);

        const pristine = new Pristine(form, defaultOptions);

        return pristine.validate();
    }
}