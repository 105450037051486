import {translate} from '@elements/translations';

export const lang = {
    en: {
        required: translate("form-validation.This field is required"),
        email:  translate("form-validation.This field requires a valid e-mail address"),
        number:  translate("form-validation.This field requires a number"),
        integer:  translate("form-validation.This field requires an integer value"),
        url:  translate("form-validation.This field requires a valid website URL"),
        tel:  translate("form-validation.This field requires a valid telephone number"),
        maxlength:  translate("form-validation.This fields length must be") + " < ${1}",
        minlength:  translate("form-validation.This fields length must be")  + " > ${1}",
        min:  translate("form-validation.Minimum value for this field is") + " ${1}",
        max:  translate("form-validation.Maximum value for this field is") + " ${1}",
        pattern:  translate("form-validation.Please match the requested format"),
        equals:  translate("form-validation.The two fields do not match"),
        default:  translate("form-validation.Please enter a correct value")
    }
};
