"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import {initInScope,onFind} from "./elements-packages/init-modules-in-scope";
import {findIn,closest,removeClass, findAllIn, findAll, on, off, is, getAttribute, addClass, hasClass, setAttribute} from '@elements/dom-utils';
import {renderTemplate} from "./renderTemplate";

const selectors = {
    base: '.js-nav',
    toggleOverlay: '.js-nav__toggle-overlay',
    showOverlay: '.js-nav__show-overlay',
    collapse: '.js-nav__collapse',
    overlay: '.js-nav__overlay',
    list: '.js-nav__list',
    listItem: '.js-nav__list-item',
    listToggle: '.js-nav__list-toggle'
};

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init () {
    onFind(selectors.base, function (baseElement) {
        let toggleOverlays = findAll(selectors.toggleOverlay);
        let showOverlays = findAll(selectors.showOverlay);

        showOverlays.forEach(function(toggleOverlay) {
            on('click', function (evt) {
                let targetId = toggleOverlay.getAttribute('data-nav-overlay-target');
                let overlayItem = findIn(targetId, baseElement);
                let renderTemplateItem = findIn('.js-nav__render-templates', overlayItem);

                if(renderTemplateItem) {
                    renderNavTemplate(renderTemplateItem);
                }

                addClass('show-overlay', overlayItem);
                setAttribute('aria-expanded', true, overlayItem);
                addClass('body--freezed', document.body);

            }, toggleOverlay);
        });

        toggleOverlays.forEach(function(toggleOverlay) {
            on('click', function(evt) {
                let targetId = toggleOverlay.getAttribute('data-nav-overlay-target');
                let overlayItem = findIn(targetId, baseElement);
                let renderTemplateItem = findIn('.js-nav__render-templates', overlayItem);

                if(renderTemplateItem) {
                    renderNavTemplate(renderTemplateItem);
                }

                if(hasClass('show-overlay', overlayItem)) {
                    hideItem(toggleOverlay);
                    hideOverlay(overlayItem);
                    removeClass('body--freezed', document.body);
                } else {
                    showItem(toggleOverlay);
                    showOverlay(overlayItem);
                    addClass('body--freezed', document.body);
                }

                function bodyClickHandler(evt) {
                    console.log('evt.target',evt.target);
                    if (!closest('.main-navbar', evt.target) && !closest('.flatpickr-calendar', evt.target) || is('.mega-nav__container', evt.target) || is('.js-nav__overlay', evt.target)) {
                        console.log('hide');
                        evt.preventDefault();
                        hideItem(toggleOverlay);
                        hideOverlay(overlayItem);
                        removeClass('body--freezed', document.body);
                    }
                }

                function showOverlay(overlay) {
                    addClass('show-overlay', overlay);
                    setAttribute('aria-expanded', true, overlay);
                    if (!isMobile()) {
                        setTimeout(function() {
                            addBodyClickHandler(bodyClickHandler);
                        }, 300);
                    }
                }
                function hideOverlay(overlay) {
                    removeClass('show-overlay', overlay);
                    setAttribute('aria-expanded', false, overlay);
                    if (!isMobile()) {
                        removeBodyClickHandler(bodyClickHandler);
                    }
                }

            }, toggleOverlay);
        });

        onFind(selectors.list, function (list) {
            let listItems = findAllIn(selectors.listItem, list);
            let listToggle = findAllIn(selectors.listToggle, list);

            listToggle.forEach(function(item) {
                on('click', function (evt) {
                    let element = evt.target;
                    evt.preventDefault();

                    let parentItem = closest(selectors.listItem, element);

                    if (hasClass('is-open', parentItem)) {
                        if (isMobile()) {
                            hideItem(parentItem);
                        } else {
                            hideItem(parentItem);
                        }
                    } else {
                        if (isMobile()) {
                            showItem(parentItem);
                        } else {
                            hideItems(listItems);
                            showItem(parentItem);
                        }
                    }
                }, item);
            });
        });

        onFind(".js-nav__screen-width", function (){
            let rightDiv = document.getElementById("js-nav__screen-width__right").getBoundingClientRect().width;
            if (((window.outerWidth/2)-60) > 360){
                if (rightDiv >= ((window.outerWidth/2)-70)){
                    addClass('main-navbar__centered--side', document.getElementById('js-nav__screen-width__center'));
                    addClass('main-navbar__brand--side', document.getElementById("js-nav__screen-width__center-logo"));
                }
            }
        })
    });
}

function addBodyClickHandler(bodyClickHandler) {
    on('click', bodyClickHandler, document.body);
}
function removeBodyClickHandler(bodyClickHandler) {
    off('click', bodyClickHandler, document.body);
}
function hideItem(item) {
    removeClass('is-open', item);
    removeClass('is-open--only-desktop', item);
    setAttribute('aria-expanded', false, item);
}
function hideItems(items) {
    items.forEach(function(item) {
        removeClass('is-open', item);
        removeClass('is-open--only-desktop', item);
        setAttribute('aria-expanded', false, item);
    });

}
function showItem(item) {
    addClass('is-open', item);
    setAttribute('aria-expanded', true, item);
}

export function renderNavTemplate(renderItem) {
    let template = findIn('.js-render-template__template',renderItem);
    let parent = renderItem.parentNode;

    if(template) {
        renderItem.innerHTML = template.innerHTML;
        removeClass('js-nav__render-template',renderItem);
        removeClass('js-render-template',renderItem);

        template.remove();

        initInScope(renderItem);
    }
}
