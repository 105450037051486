"use strict";

import initModulesInScope, {onFind, cleanUpInScope} from "./init-modules-in-scope";
import {on, findIn, empty} from "@elements/dom-utils";

const defaultSelectors = {
    base: '.js-quickfinder',
    childInputs: '.js-quickfinder__child-input',
    childContainer: '.js-quickfinder__childs'
};

export function init(selectors = defaultSelectors, template) {
    onFind(selectors.base, function (baseElement) {
        createQuickfinder(
            baseElement,
            {...defaultSelectors, ...selectors},
            template
        );
    });
}


export function createQuickfinder(quickfinder, selectors = defaultSelectors, template) {
    selectors = {
        ...defaultSelectors,
        ...selectors
    };

    let valuesByIndex = {};
    let childContainer = findIn(selectors.childContainer, quickfinder);
    let childInputs = findIn(selectors.childInputs, quickfinder);

    if (childInputs){
        on('change', function(evt) {
            cleanUpInScope(childContainer);
            empty(childContainer);

            for (let i = 1; i <= evt.target.value; i++) {
                childContainer.appendChild(template({
                        index: i,
                        value: valuesByIndex[i],
                        onChange: function (value) {
                            valuesByIndex[i] = value;
                        }
                    })
                );
            }

            initModulesInScope(findIn(selectors.childInputs, quickfinder));

        }, childInputs);
    }

}
