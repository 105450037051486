import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, hasClass, findIn, addClass} from "@elements/dom-utils";

export function init () {
    //needed for navigation
    onFind('.js-initially-show-first-collapse', function (wrapper) {
        let collapses = findAllIn('.js-initially-show-first-collapse__collapse', wrapper);
        let initiallyOpen = false;
        collapses.map(function(element) {
            if(hasClass('show', element)) {
                initiallyOpen = true;
            }
        });

        if(!initiallyOpen) {
            let firstCollapse = collapses[0];
            if(firstCollapse) {
                addClass('show', firstCollapse);
            }
        }
    });
}