import {onFind} from "./init-modules-in-scope";
import {on, findAllIn, findIn, closest} from "@elements/dom-utils";
import fetch from '@elements/fetch';

const selectors = {
    base: '.js-cart-counter',
    counter: '.js-cart-counter__count'
};

export function init() {
    onFind(selectors.base, function (element) {
        let url = element.dataset.cartCounterUrl || false;
        let counter = findIn(selectors.counter, element);

        if(url) {
            let pendingRequest = fetch( url );

            pendingRequest.then((res) => {
                return res.clone().json()
            }).then((res) => {
                if (res) {
                    counter.innerText = res.total;
                }
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    console.error(error);
                }
            });
        }
    });
}

