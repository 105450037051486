/* scripts for navigation */
import * as nav from './nav';
nav.init();

import * as affixNav from './affix-nav';
affixNav.init();

import {onFind} from "@elements/init-modules-in-scope";
import Collapse from 'bootstrap.native/dist/components/collapse-native.esm';
onFind('[data-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import Dropdown from 'bootstrap.native/dist/components/dropdown-native.esm';
onFind('[data-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import * as datepicker from '@elements/datepicker';
datepicker.init();

import * as datepickerRange from '@elements/datepicker-range';
datepickerRange.init();

import * as quickfinder from './quickfinder';
quickfinder.init();


import * as initiallyShowFirstCollapse from './initially-show-first-collapse';
initiallyShowFirstCollapse.init();

import * as cartCounter from './elements-packages/cart-counter';
cartCounter.init();